// import gmaps from "gmaps";
// import React, { useEffect } from "react";

// const GoogleMap = () => {
//   useEffect(() => {
//     const initializeMap = () => {
//       if (document.getElementById("contact-google-map")) {
//         const mapElement = document.getElementById("contact-google-map");

//         const mapLat = mapElement.getAttribute("data-map-lat");
//         const mapLng = mapElement.getAttribute("data-map-lng");
//         const iconPath = mapElement.getAttribute("data-icon-path");
//         const mapZoom = mapElement.getAttribute("data-map-zoom");
//         const mapTitle = mapElement.getAttribute("data-map-title");
//         const markers = JSON.parse(mapElement.getAttribute("data-markers"));

//         const styles = [
//           // ... (same styles as provided)
//         ];

//         const zoom = mapZoom ? parseInt(mapZoom, 10) : 15;

//         // Init map
//         const map = new gmaps({
//           div: "#contact-google-map",
//           scrollwheel: false,
//           lat: parseFloat(mapLat),
//           lng: parseFloat(mapLng),
//           styles: styles,
//           zoom: zoom,
//            apiKey: "AIzaSyCRvBPo3-t31YFk588DpMYS6EqKf-oGBSI",
//         });

//         if (iconPath) {
//           Object.keys(markers).forEach((key) => {
//             const index = markers[key];
//             const html = index[2] ? index[2] : "";

//             map.addMarker({
//               icon: index[3] || iconPath,
//               lat: parseFloat(index[0]),
//               lng: parseFloat(index[1]),
//               infoWindow: {
//                 content: html,
//               },
//             });
//           });
//         }
//       }
//     };

//     initializeMap();
//   }, []);

//   return <div id="contact-google-map" className="google-map" />;
// };

// export default GoogleMap;

/* global google */
// import React, { useEffect } from "react";

// const GoogleMap = () => {
//   useEffect(() => {
//     const initializeMap = () => {
//       const mapElement = document.getElementById("contact-google-map");
//       if (!mapElement) return;

//       const mapLat = mapElement.getAttribute("data-map-lat");
//       const mapLng = mapElement.getAttribute("data-map-lng");
//       const iconPath = mapElement.getAttribute("data-icon-path");
//       const mapZoom = mapElement.getAttribute("data-map-zoom");
//       const mapTitle = mapElement.getAttribute("data-map-title");
//       const markers = JSON.parse(mapElement.getAttribute("data-markers"));

//       const styles = [
//         {
//           featureType: "administrative",
//           elementType: "labels.text.fill",
//           stylers: [{ color: "#444444" }],
//         },
//         // ... (same styles as provided)
//       ];

//       const zoom = mapZoom ? parseInt(mapZoom, 10) : 15;

//       // Init map
//       const map = new google.maps.Map(mapElement, {
//         center: { lat: parseFloat(mapLat), lng: parseFloat(mapLng) },
//         zoom: zoom,
//         styles: styles,
//         scrollwheel: false,
//       });

//       if (iconPath) {
//         markers.forEach((marker) => {
//           const position = { lat: parseFloat(marker[0]), lng: parseFloat(marker[1]) };
//           const contentString = marker[2] ? marker[2] : "";

//           const markerInstance = new google.maps.Marker({
//             position,
//             map,
//             icon: marker[3] || iconPath,
//             title: mapTitle,
//           });

//           if (contentString) {
//             const infoWindow = new google.maps.InfoWindow({
//               content: contentString,
//             });

//             markerInstance.addListener("click", () => {
//               infoWindow.open(map, markerInstance);
//             });
//           }
//         });
//       }
//     };

//     if (typeof google !== "undefined" && google.maps) {
//       initializeMap();
//     } else {
//       const script = document.createElement("script");
//       script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCRvBPo3-t31YFk588DpMYS6EqKf-oGBSI`;
//       script.async = true;
//       script.defer = true;
//       script.onload = () => {
//         initializeMap();
//       };
//       document.head.appendChild(script);
//     }
//   }, []);

//   return <div id="contact-google-map" className="google-map" data-map-lat="YOUR_LATITUDE" data-map-lng="YOUR_LONGITUDE" data-map-zoom="15" data-markers='[["YOUR_LATITUDE", "YOUR_LONGITUDE", "InfoWindow content", "icon.png"]]' />;
// };

// export default GoogleMap;
// import React, { useEffect } from "react";
// import {mapicon} from '../../images/icons/map_Icon.png';

// const GoogleMap = () => {
//   useEffect(() => {
//     const initializeMap = () => {
//       const mapElement = document.getElementById("contact-google-map");
//       if (!mapElement) return;

//       const mapLat = parseFloat(mapElement.getAttribute("data-map-lat"));
//       const mapLng = parseFloat(mapElement.getAttribute("data-map-lng"));
//       const iconPath = mapElement.getAttribute("data-icon-path");
//       const mapZoom = parseInt(mapElement.getAttribute("data-map-zoom"), 10) || 15;
//       const mapTitle = mapElement.getAttribute("data-map-title");
//       const markers = JSON.parse(mapElement.getAttribute("data-markers"));

//       const styles = [
//         {
//           featureType: "administrative",
//           elementType: "labels.text.fill",
//           stylers: [{ color: "#ffffff" }],
//         },
//         // Add the rest of your styles here...
//       ];

//       // Init map
//       const map = new google.maps.Map(mapElement, {
//         center: { lat: mapLat, lng: mapLng },
//         zoom: mapZoom,
//         styles: styles,
//         scrollwheel: false,
//       });

//       if (iconPath && markers) {
//         markers.forEach((marker) => {
//           const position = { lat: parseFloat(marker[0]), lng: parseFloat(marker[1]) };
//           const contentString = marker[2] || "";

//           const markerInstance = new google.maps.Marker({
//             position,
//             map,
//             icon: marker[3] || iconPath,
//             title: mapTitle,
//           });

//           if (contentString) {
//             const infoWindow = new google.maps.InfoWindow({
//               content: contentString,
//             });

//             markerInstance.addListener("click", () => {
//               infoWindow.open(map, markerInstance);
//             });
//           }
//         });
//       }
//     };

//     const loadGoogleMapsScript = () => {
//       const script = document.createElement("script");
//       script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCRvBPo3-t31YFk588DpMYS6EqKf-oGBSI`;
//       script.async = true;
//       script.defer = true;
//       script.onload = initializeMap;
//       script.onerror = () => {
//         console.error("Failed to load Google Maps script.");
//       };
//       document.head.appendChild(script);
//     };

//     if (typeof google !== "undefined" && google.maps) {
//       initializeMap();
//     } else {
//       loadGoogleMapsScript();
//     }
//   }, []);
// // 26.894689613633698, 75.8401510943658
// // -17.760662248390584, 31.05710158873928
//   return (
//     <div
//       id="contact-google-map"
//       className="google-map"
//       data-map-lat="-17.760662248390584"
//       data-map-lng="31.05710158873928"
//       data-map-zoom="15"
//       data-markers='[["-17.760662248390584", "31.05710158873928", "InfoWindow content", "icon.png"]]'
//       data-icon-path='{mapicon}'  // Ensure you have a valid path to your icon
//       data-map-title="Map Title"
//     />
//   );
// };

// export default GoogleMap;



import React, { useEffect } from "react";
import Icon from '../../images/icons/Icon.png';

const GoogleMap = () => {
  useEffect(() => {
    const initializeMap = () => {
      const mapElement = document.getElementById("contact-google-map");
      if (!mapElement) return;

      const mapLat = parseFloat(mapElement.getAttribute("data-map-lat"));
      const mapLng = parseFloat(mapElement.getAttribute("data-map-lng"));
      const iconPath = mapElement.getAttribute("data-icon-path");
      const mapZoom = parseInt(mapElement.getAttribute("data-map-zoom"), 10) || 15;
      const mapTitle = mapElement.getAttribute("data-map-title");
      const markers = JSON.parse(mapElement.getAttribute("data-markers"));

      const styles = [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#ffffff" }],
        },
        // Add the rest of your styles here...
      ];

      // Init map
      const map = new google.maps.Map(mapElement, {
        center: { lat: mapLat, lng: mapLng },
        zoom: mapZoom,
        styles: styles,
        scrollwheel: false,
      });

      if (iconPath && markers) {
        markers.forEach((marker) => {
          const position = { lat: parseFloat(marker[0]), lng: parseFloat(marker[1]) };
          const contentString = marker[2] || "";

          const markerInstance = new google.maps.Marker({
            position,
            map,
            icon: marker[4] || iconPath,
            title: mapTitle,
          });

          if (contentString) {
            const infoWindow = new google.maps.InfoWindow({
              content: contentString,
            });

            markerInstance.addListener("click", () => {
              infoWindow.open(map, markerInstance);
            });
          }
        });
      }
    };

    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCRvBPo3-t31YFk588DpMYS6EqKf-oGBSI`;
      script.async = true;
      script.defer = true;
      script.onload = initializeMap;
      script.onerror = () => {
        console.error("Failed to load Google Maps script.");
      };
      document.head.appendChild(script);
    };

    if (typeof google !== "undefined" && google.maps) {
      initializeMap();
    } else {
      loadGoogleMapsScript();
    }
  }, []);

  return (
    <div
      id="contact-google-map"
      className="google-map"
      data-map-lat="-17.760662248390584"
      data-map-lng="31.05710158873928"
      data-map-zoom="15"
      data-markers='[["-17.760662248390584", "31.05710158873928", "InfoWindow content", "icon.png"]]'
      data-icon-path={Icon}  // Ensure you have a valid path to your icon
      data-map-title="GETransfer Pvt Ltd"
    />
  );
};

export default GoogleMap;
